import {make_navi} from '../makers/make_navi';
import {teach_global_signals} from '../teachers/teach_global_signals.js';

/**
 * Creates a root navi. It will know: global_signals
 * @returns {{}}
 */
export const make_root_navi = () => {
    return make_navi([teach_global_signals]);
}
