/**
 * Returns true iff the navi has a global signal with the given name
 * @param navi
 * @param signal_name
 * @returns {boolean}
 */
export const is_global_signal = (navi, signal_name) => {
    return signal_name in navi.global_signals;
}

export const get_global_signal = (navi, signal_name) => {
    return navi?.global_signals?.[signal_name] ?? null;
}

/**
 * Connects an array of watcher functions to a named global signal. If the global signal does not exist
 * the watcher functions will be saved and added should that global function ever be created. e.g. you can
 * add your watcher functions before the signal is created.
 *
 * If you connect to a value signal with a non-null value a special emit will occur for just the newly added
 * watcher functions. This makes it easy for those watcher functions to get the current value of the signal without
 * extra boilerplate.
 *
 * @param navi
 * @param signal_name - global signal name to use
 * @param watcher_fns - array of watcher functions to add to the global system.
 * @returns {*|boolean} - false if the connect did not happen immediately. Otherwise it will return the signal connected to.
 */
export const connect_to_global_signal = (navi, signal_name, watcher_fns) => {
    if (!watcher_fns) watcher_fns = [];
    if (!is_global_signal(navi, signal_name)) {
        //Need to save the watcher functions in case this gets made later.
        navi.unassigned_global_watcher_fns[signal_name] ??= [];
        navi.unassigned_global_watcher_fns[signal_name] = navi.unassigned_global_watcher_fns[signal_name].concat(watcher_fns);
        return false;
    }
    const target_global_signal = navi.global_signals[signal_name]; //TODO this assumes signal exists. But it shouldn't. Full async is the goal.
    watcher_fns.forEach((watcher_fn) => {
        target_global_signal.connect(watcher_fn);
        if (target_global_signal.value !== null) {
            watcher_fn(target_global_signal.value);
        }
    });
    return target_global_signal;
};

/**
 * Globalizes the signal within the navi. This will not overwrite is a signal with this name preexists.
 * @param navi
 * @param signal_name =
 * @param signal - the signal to globalize
 * @return signal - the global signal created (may be the exact same signal provided)
 */
export const globalize_signal = (navi, signal_name, signal) => {
    if (signal_name in navi.global_signals) {
        return navi.global_signals[signal_name]; //Don't allow overwrite
    }

    navi.global_signals[signal_name] = signal;
    if (!!navi.unassigned_global_watcher_fns[signal_name]) {
        //Assign all unassigned functions and then purge them.
        connect_to_global_signal(navi, signal_name, navi.unassigned_global_watcher_fns[signal_name]);
        delete navi.unassigned_global_watcher_fns[signal_name];
    }
    return navi.global_signals[signal_name];
};

/**
 * Emits a global signal using the provided args
 * @param navi
 * @param signal_name
 * @param args
 * @returns {*|boolean} - returns false if the named global signal did not exist.
 */
export const emit_global_signal = (navi, signal_name, ...args) => {
    if (!is_global_signal(navi, signal_name)) {
        return false;
    }
    const global_signal = navi.global_signals[signal_name];
    global_signal.emit(...args);
    return global_signal;
};
