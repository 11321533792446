/**
 * Creates a signal.
 * A signal is effectively a detached observer. These signal objects can act as variant or value signals.
 * A variant signal has no value and passes arguments from the emitter to the connector. These can be anything, but
 * it is key that all functions connected to a given signal accept the same args or handle the delta themselves.
 * A value signal has a .value attribute. If the value changes a signal is emitted and both the new and prev value are
 * emitted.
 * @returns {{disconnect: *, readonly watcher_fns: *[], emit: emit, value, connect: *}|null|*[]}
 */
export const make_signal = () => {
    let watcher_fns = []; //Need to scope here so I don't have to use `this` everywhere
    let signal_value = null; //adds value tracking for signals that need it. Changing this will emit the signal
    const emit = (...args) => {
        watcher_fns.forEach( (watcher_fn) => watcher_fn(...args) );
    };
    return {
        get watcher_fns() { return watcher_fns },
        connect: (watcher_fn) => {
            watcher_fns.push(watcher_fn);
        },
        disconnect: (watcher_fn_to_remove) => {
            watcher_fns = watcher_fns.filter((watcher_fn) => watcher_fn === watcher_fn_to_remove);
        },
        emit,
        get value() {
            return signal_value
        },
        set value(value) {
            const prev_value = signal_value;
            signal_value = value;
            emit(value, prev_value);
        }
    }
};
