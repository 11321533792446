/**
 * Teaches your navi global signals. Afterwards a navi can be used
 * for global signal related functions.
 * @param navi
 */
export const teach_global_signals = (navi) => {
    if (navi instanceof Object) {
        navi.global_signals ??= {};
        navi.unassigned_global_watcher_fns ??= {};
    }
};


