/**
 * Creates a basic navi. By default a navi is just a JS object.
 * Navi's are designed to be transformed by the API to serve different
 * connection and refactoring purposes.
 * Hi there
 * @param teaching_functions
 * @returns {{}}
 */
export const make_navi = (teaching_functions) => {
    const navi = {};
    teaching_functions.forEach(fn => fn(navi));
    return navi;
};
